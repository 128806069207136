/* eslint-disable react-hooks/exhaustive-deps */
import { faArchive, faEye, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, debounce, Stack, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem, GridCellParams, GridRowParams, GridSortItem } from "@mui/x-data-grid";
import { useAtomValue } from "jotai";

// === Import LOCAL
import { DateTime } from "luxon";
/* eslint-disable react/jsx-key */
// === Import NPM
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FieldValues } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { userAtom } from "../../../../atoms/Atoms";
import { IFilterConfigurations, IFilterTypes, IShape } from "../../../../interfaces/Filter";
import { IOiraImportError, IOiraShortForm } from "../../../../interfaces/OiraForm";
import { Role } from "../../../../interfaces/User";
import { AmetraTotalCount } from "../../../../resources/AppConstants";
import colors from "../../../../resources/cssConstant";
import { backOfficeRoutes } from "../../../../resources/Routes";
import { StatusCode } from "../../../../resources/StatusCode";
import FilterService from "../../../../services/FilterService";
import FormService from "../../../../services/FormService";
import Header from "../../../Commons/Header/Header";
import GenericDatagrid from "../../../Generics/GenericDatagrid/GenericDatagrid";
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";
import GenericFilter from "../../../Generics/GenericFilter/GenericFilter";

interface OiraFilterSchema {
  title: string;
}

const oiraFilterSchema = yup.object<IShape<OiraFilterSchema>>({
  title: yup.string().max(255, "Le nom ne doit pas excéder 255 caractères."),
});

// filter Inputs
const oiraFilter: IFilterConfigurations<OiraFilterSchema> = {
  title: {
    id: "title",
    name: "title",
    label: "Nom du questionnaire",
    type: IFilterTypes.TEXT,
  },
};

function OiraForms() {
  const navigate = useNavigate();

  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false);
  const [oiraForms, setOiraForms] = useState<IOiraShortForm[]>([]);
  const [oiraInfos, setOiraInfos] = useState<IOiraShortForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const user = useAtomValue(userAtom);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(10);
  const [currentFilter, setCurrentFilter] = useState<OiraFilterSchema | undefined>(undefined);
  const hiddenFileInput = useRef(null);

  const fetchOiraForms = async (filterInputValue: OiraFilterSchema | undefined = undefined) => {
    setCurrentFilter(filterInputValue);
    setOiraForms([]);
    setLoading(true);
    const retrievedExperts = await FilterService().groupedOiraFilter(filterInputValue, page, rowsPerPage);
    setRowCount(+(retrievedExperts.headers[AmetraTotalCount] ?? retrievedExperts.data.length));
    setOiraForms(retrievedExperts?.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (currentFilter) {
      fetchOiraForms(currentFilter);
    } else {
      fetchOiraForms();
    }
    setLoading(false);
  }, [page, rowsPerPage]);

  const debounceFetchOiraForms = useCallback(debounce(fetchOiraForms, 500), []);

  const onFilterSubmit = async (data: FieldValues) => {
    const filterInput = {
      title: data.title,
    };
    await debounceFetchOiraForms(filterInput.title !== "" ? filterInput : undefined);
  };

  const importOiraForm = async (event) => {
    const fileUploaded = event.target.files[0];
    const response = await FormService().importOiraForm(fileUploaded);
    if (response.status === StatusCode.CREATED) {
      toast.success(`Vous avez bien importé l'OiRA ${fileUploaded?.name}.`);
      await fetchOiraForms();
    } else {
      const errorDTO: IOiraImportError = response.data as unknown as IOiraImportError;
      toast.error(
        `Impossible d'importer cet OiRA : la cellule ligne ${errorDTO.row} colonne "${errorDTO.headerName}" est vide.`,
      );
    }
  };

  const renderOiraFormPage = (title: string, formUuid: string, groupUuid: string) => {
    navigate(generatePath(backOfficeRoutes.specific_form, { formTemplateName: title.split("/").join("_") }), {
      state: { groupUuid, title, formUuid },
    });
  };

  const archiveOira = async (uuid: string) => {
    const response = await FormService().unpublishOira(uuid);
    if (response.status === StatusCode.NO_CONTENT) {
      toast.success(`Vous avez bien archivé l'OiRA ${oiraInfos?.title}. Aucune version n'est publiée.`);
      setOpenArchiveModal(false);
      await fetchOiraForms();
    } else {
      toast.error("Impossible d'archiver cet OiRA.");
      setOpenArchiveModal(false);
    }
  };

  const oiraColumns = [
    {
      field: "uuid",
      hide: true,
    },
    {
      field: "groupUuid",
      hide: true,
    },
    {
      field: "title",
      headerName: "Nom",
      flex: 1,
      sortable: false,
    },
    {
      field: "creationDate",
      headerName: "Date",
      flex: 1,
      valueFormatter: (params: GridCellParams) => DateTime.fromISO(params.value).toLocaleString(),
      sortable: false,
    },
    {
      field: "groupPublished",
      headerName: "Publié",
      flex: 1,
      sortable: false,
      valueGetter: (params: GridCellParams) => (params.value === true ? "Oui" : "Non"),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      headerAlign: "center",
      width: 200,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={`gaci1o-${params.row?.uuid}`}
          icon={
            <Tooltip arrow title="Consulter ce questionnaire">
              <FontAwesomeIcon size="lg" color={colors.primary} icon={faEye} />
            </Tooltip>
          }
          label="edit"
          onClick={() => {
            setOiraInfos(params.row);
            renderOiraFormPage(params.row.title, params.row.uuid, params.row.groupUuid);
          }}
        />,
        <GridActionsCellItem
          key={`gaci2o-${params.row?.uuid}`}
          sx={{ display: user.role === Role.ADMIN ? "block" : "none" }}
          disabled={!params.row.groupPublished}
          icon={
            <Tooltip arrow title="Archiver ce questionnaire">
              <FontAwesomeIcon
                size="lg"
                color={params.row.groupPublished ? colors.primary : colors.disabledIcon}
                icon={faArchive}
              />
            </Tooltip>
          }
          label="delete"
          onClick={() => {
            setOiraInfos(params.row);
            setOpenArchiveModal(true);
          }}
        />,
      ],
    },
  ];

  const sortModel: GridSortItem[] = [{ field: "creationDate", sort: "desc" }];

  const renderArchiveModal = () => (
    <GenericDialog
      title="Archiver un Questionnaire OiRA"
      openDialog={openArchiveModal}
      handleClose={() => setOpenArchiveModal(false)}
      confirmLabel="Confirmer"
      onValid={() => {
        if (oiraInfos) archiveOira(oiraInfos.groupUuid);
      }}
    >
      <Stack>
        <Typography variant="modalContent">
          Souhaitez vous réellement archiver le questionnaire {oiraInfos?.title} ?
        </Typography>
        <Typography variant="modalContent">Il ne sera plus visible côté utilisateur.</Typography>
      </Stack>
    </GenericDialog>
  );

  return (
    <>
      {renderArchiveModal()}
      <Header title="Questionnaire OiRA">
        <Button
          variant="contained"
          component="label"
          sx={{ backgroundColor: colors.mainOrange, borderRadius: 3, padding: "8px 22px" }}
          startIcon={<FontAwesomeIcon size="xl" icon={faUpload} />}
        >
          Importer un nouveau questionnaire
          {/*
           */}
          <input
            hidden
            accept=".xlsx, .xls, .csv"
            type="file"
            ref={hiddenFileInput}
            onChange={importOiraForm}
            onClick={(event) => {
              // This is to reset files, so we can try uploading again the same file
              const nativeEvent = event.nativeEvent.target as HTMLInputElement;
              nativeEvent.value = "";
            }}
          />
        </Button>
      </Header>
      <Box sx={{ px: 15, py: 4 }}>
        <GenericFilter onSubmit={onFilterSubmit} schema={oiraFilterSchema} filterInputs={oiraFilter} />
        <GenericDatagrid
          rows={oiraForms}
          columns={oiraColumns}
          loading={loading}
          sortModel={sortModel}
          page={page}
          onPageChange={setPage}
          size={rowsPerPage}
          onPageSizeChange={setRowsPerPage}
          rowCount={rowCount}
        />
      </Box>
    </>
  );
}

export default OiraForms;
