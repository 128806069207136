import { faArchive, faBars, faCheck, faCheckDouble, faEye, faFolderOpen, faPlusCircle, faQuestionCircle, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, debounce, Divider, ListItemIcon, Menu, MenuItem, MenuList, Pagination, Stack, Typography } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { currentComplementaryFormAtom, userAtom } from "../../../../../atoms/Atoms";
import { ICompany, ICompanyShort } from "../../../../../interfaces/Company";
import { IFilterConfigurations, IFilterTypes, IShape } from "../../../../../interfaces/Filter";
import { IShortForm, IShortFormFilter, ProgressStatus, Type } from "../../../../../interfaces/Form";
import { Role } from "../../../../../interfaces/User";
import { AmetraTotalCount } from "../../../../../resources/AppConstants";
import colors from "../../../../../resources/cssConstant";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../../../resources/Routes";
import { StatusCode } from "../../../../../resources/StatusCode";
import ComplementaryFormService from "../../../../../services/ComplementaryFormService";
import FilterService from "../../../../../services/FilterService";
import { objectFilter } from "../../../../../utils/ObjectFilter";
import GenericIconButton from "../../../../Generics/buttons/GenericIconButton";
import GenericDialog from "../../../../Generics/GenericDialog/GenericDialog";
import GenericFilter from "../../../../Generics/GenericFilter/GenericFilter";
import ArchiveDocumentButton from "../../../DocumentActions/ArchiveDocumentButton/ArchiveDocumentButton";
import DeleteDocumentButton from "../../../DocumentActions/DeleteDocumentButton/DeleteDocumentButton";
import DownloadDocumentButton from "../../../DocumentActions/DownloadDocumentButton/DownloadDocumentButton";
import DuplicateDocumentButton from "../../../DocumentActions/DuplicateDocumentButton/DuplicateDocumentButton";

interface ComplementaryFormMenuOptionsProps {
  company: ICompanyShort | ICompany;
}

interface FilterShape {
  id: string;
  name: string;
}

interface DuerpFilterSchema {
  company: FilterShape;
  duerp: FilterShape;
}

interface DuerpRequestSchema {
  documentType: Type.DUERP;
  companyName: string;
  reference: string;
  sorts: string;
  progressStatusIn: string;
}

const duerpFilterSchema = yup.object<IShape<DuerpFilterSchema>>({
  company: yup.string().max(70, "Le nom de l'entreprise ne doit pas dépasser 70 caractères."),
  duerp: yup.string().max(70, "Le numéro de référence du DUERP ne doit pas dépasser 70 caractères."),
});

export default function ComplementaryFormMenuOptions({ company }: Readonly<ComplementaryFormMenuOptionsProps>) {
  const user = useAtomValue(userAtom);
  const navigate = useNavigate();
  const [complementaryForm, setComplementaryForm] = useAtom(currentComplementaryFormAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDuerpModal, setOpenDuerpModal] = useState<boolean>(false);
  const [shortDuerps, setShortDuerps] = useState<IShortForm[]>([]);
  const [searchedShortDuerps, setSearchedShortDuerps] = useState<IShortFormFilter[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [currentFilter, setCurrentFilter] = useState<DuerpRequestSchema | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setShortDuerps(complementaryForm.duerps);
    setSearchedShortDuerps([]);
  }, []);

  useEffect(() => {
    setAnchorEl(null);
    setShortDuerps(complementaryForm.duerps);
    setSearchedShortDuerps([]);
  }, [openDuerpModal]);

  const filterRequest = async (filterInputs: DuerpRequestSchema | null = null) => {
    setCurrentFilter(filterInputs);
    const size = 4;
    const requestParams = objectFilter(filterInputs, (filterInput) => filterInput !== "" && filterInput !== undefined);
    const res = await FilterService().shortDocumentFilter(requestParams, page, size);
    if (res.status === StatusCode.OK) {
      setSearchedShortDuerps(res.data);
      const totalDuerpCount = +(res.headers[AmetraTotalCount] ?? res.data.length);
      setTotalPageNumber(Math.ceil(totalDuerpCount / size));
    } else {
      setSearchedShortDuerps([]);
    }
  };

  const duerpFilter: IFilterConfigurations<DuerpFilterSchema> = {
    company: {
      id: "company",
      name: "company",
      label: "Raison sociale de l'entreprise",
      type: IFilterTypes.TEXT,
    },
    duerp: {
      id: "duerp",
      name: "duerp",
      label: "Numéro de référence du DUERP",
      type: IFilterTypes.TEXT,
    },
  };

  const debounceFilterRequest = useCallback(debounce(filterRequest, 500), []);

  useEffect(() => {
    if (currentFilter) {
      debounceFilterRequest(currentFilter);
    } else {
      setSearchedShortDuerps([]);
    }
  }, [page]);

  const onFilterSubmit = async (data: FieldValues) => {
    if (data.company.trim().length === 0 && data.duerp.trim().length === 0) {
      return;
    }

    const filterInputs: DuerpRequestSchema = {
      documentType: Type.DUERP,
      companyName: data.company,
      reference: data.duerp,
      sorts: "progressStatus,asc,validationDate,desc",
      progressStatusIn: "FINALIZED,NOT_FINALIZED",
    };

    setPage(0); // Reset the page for a new filter
    debounceFilterRequest(filterInputs);
  };

  const saveEditedDuerps = async () => {
    const duerpUuids = shortDuerps.map((duerp) => duerp.uuid);
    const res = await ComplementaryFormService().associateDuerpsToComplementaryForm(complementaryForm.uuid, duerpUuids);
    if (res.data) {
      setComplementaryForm(res.data);
      toast.success("Vos modifications ont bien été sauvegardées.");
    } else {
      toast.error("Impossible de sauvegarder vos modifications pour le moment.");
    }
    setOpenDuerpModal(false);
  };

  const deleteDuerp = (duerpUuid: string) => {
    setShortDuerps(shortDuerps.filter((duerpShort) => duerpShort.uuid !== duerpUuid));
  };

  const addDuerp = (newDuerp: IShortForm) => {
    const updatedDuerps = [...shortDuerps];
    if (updatedDuerps.filter((duerp) => duerp.uuid === newDuerp.uuid).length > 0) {
      toast.error("Vous avez déjà associé ce DUERP.");
    } else {
      updatedDuerps.push(newDuerp);
      setShortDuerps(updatedDuerps);
    }
  };

  const viewDuerp = (duerpRefNumber: string) => {
    const path = user.role === Role.COMPANY_USER ? frontOfficeRoutes.duerp : backOfficeRoutes.duerp;

    window.open(`${window.location.origin}${generatePath(path, { refNumber: duerpRefNumber })}`, "_blank");
  };

  const renderIcon = (progressStatus: ProgressStatus) => {
    switch (progressStatus) {
      case ProgressStatus.NOT_FINALIZED:
        return <FontAwesomeIcon size="xl" icon={faSync} color={colors.notFinalized} />;
      case ProgressStatus.COMPLETED:
        return <FontAwesomeIcon size="xl" icon={faCheck} color={colors.completed} />;
      case ProgressStatus.FINALIZED:
        return <FontAwesomeIcon size="xl" icon={faCheckDouble} color={colors.finalized} />;
      case ProgressStatus.ARCHIVED:
        return <FontAwesomeIcon size="xl" icon={faArchive} color={colors.archived} />;
      default:
        return <FontAwesomeIcon size="xl" icon={faQuestionCircle} color={colors.accompanied} />;
    }
  };

  const renderDuerpModal = () => (
    <GenericDialog
      openDialog={openDuerpModal}
      handleClose={() => setOpenDuerpModal(false)}
      title="Visualiser/Modifier les DUERPs associés"
      confirmLabel="Enregistrer"
      onValid={() => {
        saveEditedDuerps();
      }}
    >
      <Stack spacing={5} width="100%">
        <GenericFilter onSubmit={onFilterSubmit} schema={duerpFilterSchema} filterInputs={duerpFilter} />
        <Stack spacing={2}>
          <Typography variant="body2">Résultat de la recherche :</Typography>
          {searchedShortDuerps.length > 0 ? (
            <Stack spacing={5}>
              <Stack>
                {searchedShortDuerps.map((shortDuerp) => (
                  <Stack direction="row" key={shortDuerp.uuid} justifyContent="space-between" alignItems="center">
                    <Stack direction="row" key={shortDuerp.uuid} alignItems="center" spacing={2}>
                      {renderIcon(shortDuerp.progressStatus)}
                      <Typography variant="body1">{`DUERP n° ${shortDuerp.reference} (${shortDuerp.companyName})`}</Typography>
                    </Stack>

                    <Stack direction="row">
                      <GenericIconButton
                        icon={faEye}
                        tooltip="Consulter le DUERP"
                        onClick={() => viewDuerp(shortDuerp.reference)}
                        size="sm"
                      />
                      <GenericIconButton
                        icon={faPlusCircle}
                        tooltip="Ajouter ce DUERP"
                        onClick={() => addDuerp(shortDuerp)}
                        size="sm"
                      />
                    </Stack>
                  </Stack>
                ))}
              </Stack>
              <Pagination
                page={page + 1}
                count={totalPageNumber}
                size="small"
                siblingCount={0}
                onChange={(event: ChangeEvent<unknown>, newPage: number) => {
                  setPage(newPage - 1);
                }}
                color="primary"
                sx={{
                  alignSelf: "flex-end",
                  "& .MuiPaginationItem-root": {
                    color: colors.primary,
                  },
                  "& .MuiPaginationItem-selected": {
                    color: colors.white,
                  },
                }}
              />
            </Stack>
          ) : (
            <Typography variant="body1">Aucun résultat</Typography>
          )}
        </Stack>
        <Divider />
        {shortDuerps.length > 0 ? (
          <Stack spacing={1}>
            <Typography variant="body2">DUERPs associés :</Typography>
            {shortDuerps.map((shortDuerp) => (
              <Stack direction="row" key={shortDuerp.uuid} justifyContent="space-between" alignItems="center">
                <Typography variant="body1">{`DUERP n° ${shortDuerp.reference}`}</Typography>
                <GenericIconButton
                  icon={faTrash}
                  tooltip="Supprimer ce DUERP"
                  color={colors.deleteIcon}
                  onClick={() => deleteDuerp(shortDuerp.uuid)}
                  size="sm"
                />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Typography variant="body2">Aucun DUERP associé</Typography>
        )}
      </Stack>
    </GenericDialog>
  );

  const updateComplementaryForm = () => {
    const updatedForm = { ...complementaryForm };
    updatedForm.progressStatus = ProgressStatus.ARCHIVED;
    setComplementaryForm(updatedForm);
  };

  return (
    <>
      {user.role !== Role.COMPANY_USER && renderDuerpModal()}
      <GenericIconButton onClick={(event) => setAnchorEl(event.currentTarget)} icon={faBars} tooltip="Options" />
      <Menu
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          {user.role !== Role.COMPANY_USER && (
            <Box>
              <MenuItem onClick={() => setOpenDuerpModal(true)}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFolderOpen} size="xs" color={colors.primary} />
                </ListItemIcon>
                <Typography variant="body1">Visualiser/Modifier les DUERPs associés</Typography>
              </MenuItem>
              <DownloadDocumentButton
                documentType={Type.COMPLEMENTARY_FORM}
                documentUuid={complementaryForm.uuid}
                menuButton
              />
            </Box>
          )}
          <DuplicateDocumentButton
            documentType={Type.COMPLEMENTARY_FORM}
            documentUuid={complementaryForm.uuid}
            company={company}
            menuButton
          />
          {user.role === Role.COMPANY_USER ? (
            <DeleteDocumentButton
              document={complementaryForm}
              onValid={() => navigate(frontOfficeRoutes.home)}
              menuButton
            />
          ) : (
            <ArchiveDocumentButton document={complementaryForm} onValid={() => updateComplementaryForm()} menuButton />
          )}
        </MenuList>
      </Menu>
    </>
  );
}
