/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { allOrganizationsAtom, userAtom, userCompaniesAtom } from "../../../atoms/Atoms";
import { ICompanyNameFilter, ICompanyShort } from "../../../interfaces/Company";
import { IFilterConfigurations, IFilterTypes, IOptionValue, IShape } from "../../../interfaces/Filter";
import { IOiraShortForm } from "../../../interfaces/OiraForm";
import { Role } from "../../../interfaces/User";
import { AmetraTotalCount, generalRowsPerPageOptions } from "../../../resources/AppConstants";
import { StatusCode } from "../../../resources/StatusCode";
import CompanyService from "../../../services/CompanyService";
import FilterService from "../../../services/FilterService";
import FormService from "../../../services/FormService";
import UserService from "../../../services/UserService";
import { objectFilter } from "../../../utils/ObjectFilter";
import LoadingSkeleton from "../../Generics/GenericDatagrid/LoadingSkeleton";
import GenericFilter from "../../Generics/GenericFilter/GenericFilter";
import CompanyForm from "../Companies/container/CompanyForm";
import CollapsibleCompany from "./container/CollapsibleCompany/CollapsibleCompany";

interface CompanyShape {
  id: string;
  name: string;
}
interface CompanyFilterSchema {
  company: CompanyShape;
  membershipNumber: string;
  membershipOrganization: string;
}

const companyFilterSchema = yup.object<IShape<CompanyFilterSchema>>({
  company: yup.object().nullable(),
  membershipNumber: yup.string().max(70, "Le numéro d'adhérent ne doit pas excéder 70 caractères."),
  membershipOrganization: yup.string(),
});

interface CollapsibleCompanyTableProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export default function CollapsibleCompanyTable({ openModal, setOpenModal }: CollapsibleCompanyTableProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [userCompanies, setUserCompanies] = useAtom(userCompaniesAtom);
  const [companies, setCompanies] = useState<ICompanyShort[]>([]);
  const [allCompanies, setAllCompanies] = useState<ICompanyNameFilter[]>([]);
  const [oiras, setOiras] = useState<IOiraShortForm[]>([]);
  const user = useAtomValue(userAtom);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(10);
  const [currentFilter, setCurrentFilter] = useState<IFilterConfigurations<ObjectShape> | null>(null);
  const organizations = useAtomValue(allOrganizationsAtom);

  const filterRequest = async (filterInputs) => {
    setCurrentFilter(filterInputs);
    const requestParams = objectFilter(
      filterInputs,
      (filterInput) => filterInput !== "" && filterInput !== undefined && filterInput !== "ALL",
    );
    const response = await FilterService().companyFilter(requestParams, page, rowsPerPage);
    if (response.status === StatusCode.OK) {
      setCompanies(response.data);
      setRowCount(+(response.headers[AmetraTotalCount] ?? response.data.length));
    }
  };

  const debouncedFilterRequest = useCallback(debounce(filterRequest, 500), []);

  const fetchOiras = async () => {
    const res = await FormService().getAllPublishedOiras();
    setOiras(res.data ?? []);
  };

  const fetchCompanies = async () => {
    setCurrentFilter(null);
    await filterRequest({});
  };

  const getAllCompanies = async () => {
    const res = await CompanyService().getCompaniesNames();
    if (res?.data) {
      setAllCompanies(res.data);
    }
  };
  const updateUserCompanies = async () => {
    await fetchCompanies();
    if (user.role === Role.COMPANY_USER) {
      const res = await UserService().getUserCompanies(user.uuid);
      setUserCompanies(res.data ?? []);
    } else {
      await getAllCompanies();
    }
  };

  useEffect(() => {
    fetchOiras();
    if (user.role !== Role.COMPANY_USER) {
      getAllCompanies();
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (currentFilter) {
      filterRequest(currentFilter);
    } else {
      fetchCompanies();
    }
    setLoading(false);
  }, [page, rowsPerPage]);

  const membershipOrganizationOptions: IOptionValue[] = [{ id: 1, value: "ALL", label: "Tous" }].concat(
    organizations.map((organization, index) => ({
      id: index,
      value: organization.organizationName,
      label: organization.organizationName,
    })),
  );

  const companyFilter: IFilterConfigurations<CompanyFilterSchema> = {
    company: {
      id: "company",
      name: "company",
      label: "Raison sociale",
      type: IFilterTypes.AUTOCOMPLETE,
      optionValues: user.role === Role.COMPANY_USER ? userCompanies : allCompanies,
    },
    membershipNumber: {
      id: "membershipNumber",
      name: "membershipNumber",
      label: "Numéro d'adhérent",
      type: IFilterTypes.TEXT,
    },
    membershipOrganization: {
      id: "membershipOrganization",
      name: "membershipOrganization",
      label: "Service de prévention et de santé au travail",
      type: IFilterTypes.SELECT,
      optionValues: membershipOrganizationOptions,
    },
  };

  const onFilterSubmit = (data: FieldValues) => {
    const filterInputs = {
      companyName: data.company?.name ?? "",
      membershipNumber: data.membershipNumber,
      membershipOrganization: data.membershipOrganization,
    };
    debouncedFilterRequest(filterInputs);
  };

  const renderTable = () => (
    <>
      <CompanyForm setOpenModal={setOpenModal} openModal={openModal} fetchCompanies={updateUserCompanies} />
      <GenericFilter onSubmit={onFilterSubmit} schema={companyFilterSchema} filterInputs={companyFilter} />
      <Paper variant="outlined" sx={{ borderRadius: 5 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="5%" />
                <TableCell>Raison sociale ou dénomination</TableCell>
                <TableCell>Numéro adhérent</TableCell>
                {user.role !== Role.COMPANY_USER && (
                  <>
                    <TableCell align="center">Nombre de DUERPs</TableCell>
                    <TableCell align="center">Nombre de QCs</TableCell>
                  </>
                )}
                <TableCell width="15%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.length > 0 ? (
                companies.map((company) => (
                  <CollapsibleCompany
                    key={company.uuid}
                    shortCompany={company}
                    oiras={oiras}
                    fetchCompanies={fetchCompanies}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={user.role === Role.COMPANY_USER ? 4 : 6}>
                    <Typography variant="body2">Aucun résultat</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={generalRowsPerPageOptions}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event: MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)}
          onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
        />
      </Paper>
    </>
  );

  return loading ? <LoadingSkeleton /> : renderTable();
}
